import React from 'react';

import SEO from '../components/seo';

const TermsOfUseAndPrivacyPolicy = () => (
  <>
    <SEO title="Terms Of Use And Privacy Policy" />
    <section className="w-full flex flex-col justify-center px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl my-6 md:my-12 lg:my-24">
      <div className="w-full text-center my-12">
        <h1 className="font-bold text-xs-h5 sm:text-sm-h5 md:text-md-h5 lg:text-md-h5">
          TERMS OF USE AND PRIVACY POLICY
        </h1>
      </div>
      <div className="w-full px-4 md:px-6 lg:px-12 py-6 border border-light-gray shadow-2xl text-xs-p text-justify">
        <p className="font-bold mt-2"> 1. Introduction</p>
        <p>
          LiNEFORYOU, Lda, a limited liability company incorporated under
          Portuguese law, with the Number of Identification of Legal Entity
          510179576, with headquarters office at Rua Belmiro De Oliveira Nº1187
          R/C, Selho Sao Lourenço, 4800-134 Guimarães, Portugal.
        </p>
        <p>
          Please, carefully read this document. It contains important
          information related to your rights and duties concerning your access
          and use of this website.
        </p>
        <p>
          On accessing and using this website, the user declares to have read,
          understood and accepted these general terms and privacy policy,
          without necessity of any further action or consent.
        </p>
        <p>
          If the user does not agree with these general terms and privacy
          policy, he should immediately leave the website.
        </p>
        <p className="font-bold mt-2">2. Property Rights</p>
        <p>
          The following features are exclusively owned by LiNEFORYOU, Lda,
          unless stated otherwise, and are protected by intellectual and
          industrial property rights:
        </p>
        <p>
          This website, including its contents, texts, images, trademarks,
          graphics, logos, buttons, software archives, databases, colour
          combinations, structure, selection, composition and display of
          content, font and object;
        </p>
        <p>
          in general, any intellectual creation in this website, as well as the
          website itself, as a multimedia work;
        </p>
        <p>the trademark "LiNE";</p>
        <p>the domain name "www.line-textiles.com";</p>
        <p>
          It is expressly forbidden to any third parties the use, reproduction
          or usurpation of any of these features.
        </p>
        <p className="font-bold mt-2">3. Access and Use</p>
        <p>
          It is expressly forbidden to access and use this website using
          modified forms of software, namely with the purpose of obtaining any
          type of unauthorised access.
        </p>
        <p>
          It is also expressly forbidden to create or to introduce in this
          website any type of virus, worms, trojans, scripts or any other code
          or program with destructive or malicious characteristics.
        </p>
        <p className="font-bold mt-2">4. Privacy</p>
        <p>
          LiNEFORYOU, Lda., as the responsible entity by this website, respects
          the right to privacy and to the protection of personal data, in
          accordance to the legal rules in place.
        </p>
        <p className="font-bold mt-2">5. Cookies</p>
        <p>
          This website uses cookies, with the purpose of improving its
          performance and the navigation experience of the user.
        </p>
        <p>
          Cookies are small text files stored in your computer though your
          internet browser, retaining information only related to your
          preferences, not including, as such, your personal data. Cookies aim
          exclusively at improving the website navigation, allowing it to
          recognise the device of the user in a future visit to the website.
        </p>
        <p>
          The user has the option not to authorise the reception of cookies in
          his computer.
        </p>
        <p>This website uses the following types of cookies:</p>
        <p>
          Persistent cookies: stored in the browser level of your devices (pc,
          mobile and tablet) and used every new visit to the website;
        </p>
        <p>
          Session cookies: temporary and remain in the archive of cookies of the
          browser until leaving the website. The information obtained by these
          cookies is used to analyse web traffic patterns, allowing us to
          identify problems and provide a better navigation experience.
        </p>
        <p>
          If the user does not agree with these general terms and privacy
          policy, he should immediately leave the website.
        </p>
        <p>
          To obtain more information on cookies and its use, we suggest the
          consultation of the following link, available only in English
          language: www.allaboutcookies.org.
        </p>
        <p className="font-bold mt-2">6. Hyperlinks</p>
        <p>
          LiNEFORYOU, Lda. reserves the right to include in its website links to
          other websites. The user, following that links, must review and accept
          the rules of the websites before its use.
        </p>
        <p>
          BytePitch – Software Engineering, Lda. does not assure the quality of
          other websites and it is not liable for its content or other
          functionalities of other websites.
        </p>
        <p className="font-bold mt-2">
          7. Governing Law and Dispute Resolution
        </p>
        <p>
          These general terms and privacy policy shall be governed by, construed
          under and interpreted in accordance with the Portuguese law.
        </p>
        <p>
          These general terms and privacy policy are submitted to the exclusive
          jurisdiction of the Portuguese courts.
        </p>
        <p className="mt-4">Version of August 7th 2020.</p>
      </div>
    </section>
  </>
);

export default TermsOfUseAndPrivacyPolicy;
